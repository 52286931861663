import Vue from "vue";
import VueRouter from "vue-router";
import { Toast } from "vant";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/product",
    name: "Product",
    component: () => import("@/views/product/index.vue"),
    meta: {
      title: "承诺达标合格证",
      keepAlive: false,
    },
  },
  {
    path: "/model",
    name: "Model",
    component: () => import("@/views/model/index.vue"),
    meta: {
      title: "承诺达标合格证",
      keepAlive: false,
    },
  },
  {
    path: "/subject",
    name: "Subject",
    component: () => import("@/views/subject/index.vue"),
    meta: {
      title: "主体信息",
      keepAlive: false,
    },
  },
  {
    path: "/fromDateils",
    name: "FromDateils",
    component: () => import("@/views/fromDateils/index.vue"),
    meta: {
      title: "追溯信息",
      keepAlive: false,
    },
  },
  {
    path: "/produce",
    name: "Produce",
    component: () => import("@/views/produce/index.vue"),
    meta: {
      title: "生产信息",
      keepAlive: false,
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  // base: "/bagcloud/",
  // base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  Toast.clear();
  next();
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});
router.afterEach((to, from) => {
  document.documentElement.scrollTop = 0.01;
  document.body.scrollTop = 0.01;
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
});
export default router;
